import React from 'react';
import { MenuWrapper } from './Menu.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

function Menu() {
   const { t } = useTranslation();
   return (
   <MenuWrapper>
      <Container className='top-menu'>
         <Row className="container d-flex align-items-center flex-column">
            {/* <nav className="navbar navbar-expand-lg text-uppercase fixed-top" id="mainNav"> */}
            <nav className="navbar navbar-expand-lg text-uppercase absolute-top" id="mainNav">
               <Col sm={3}> 
               <img className="logo-image" src={require('../../assets/images/OnStar-Logo.webp')} alt='Onstar'/>
               </Col>
               <Col sm={9}>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                  <img className="hamburger-menu" src={require('../../assets/images/page-component/const/mobile/menu-line.png')} alt='Menu'/>
                </button>
                  <div class="collapse navbar-collapse" id="navbarResponsive">
                     <ul className="navbar-nav ms-auto">
                        <li className="nav-item mx-0 mx-lg-1"><a name='WATCH NOW' className="nav-link py-3 px-0 px-lg-3 rounded" href="https://www.youtube.com/playlist?list=PLW4jLjo-ouGci3fWap36lxcWmA2EyFJ0O">{t('m_watch_now')}</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a name='THE GAME'  className="nav-link py-3 px-0 px-lg-3 rounded" href="#game">{t('m_the_game')}</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a name='HOSTS' className="nav-link py-3 px-0 px-lg-3 rounded" href="#hosts">{t('m_hosts')}</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a name='GAMERS' className="nav-link py-3 px-0 px-lg-3 rounded" href="#gamers">{t('m_gamers')}</a></li>
                     </ul>
                  </div>
               </Col>
            </nav>
         </Row>
      </Container>
   </MenuWrapper>
 )
};


export default Menu;
