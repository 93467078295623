import React from 'react';
import { Helmet } from 'react-helmet';
import { TeaserWrapper } from './TeaserAr.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';


function TeaserAr() {
   return (
 <TeaserWrapper className='teaser-countdown'>
    <Helmet>
        <title>أبطال القيادة من أونستار</title>
      </Helmet>
   <Container>
         <Row className="teaser container d-flex align-items-center flex-column">
            <Col sm={12}>
                  <h2>كونوا على موعد مع حدث مميز - حدث رائع على الأبواب - ترقّبوا مفاجأتنا الاستثنائية</h2>
                  <div className="countdown">
                  <FlipClockCountdown 
                  to={new Date('2023-09-15').getTime('13:59:59') + 24 * 3600 * 1000 + 5000} 
                  labels={['يوم', 'ساعة', 'دقيقة', 'ثانية']} 
                  onTimeUp={() => console.log("Time's up ⏳")}
                  />
                  </div>
               </Col>
         </Row>
         <Row>
            <Col sm><p className="stay-tuned">ترقّبونا - لا تفوتوا الحدث - كونوا على الموعد</p></Col>
         </Row>
      </Container>
 </TeaserWrapper>
  )
};


export default TeaserAr;
