import React from 'react';
import { PageFiveWrapper } from './PageFive.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";



function PageFive() {
   const { t } = useTranslation();

   return (
      <PageFiveWrapper>
         <Helmet>
            <script>{
               `var cards = document.querySelectorAll('.flip-card');
               [...cards].forEach((card) => {
                  card.addEventListener('click', function () {
                     console.log('click is working here');
                     card.classList.toggle('is-flipped');
                  });
               });`
            }</script>
         </Helmet>
         <Container fluid className='section page-five gamers' id="gamers">
            <Row className='p-five-one'>
               <Col className='pagefive-middle top-text small-text'>
                  <h5>{t('the')}</h5>
                  <h1>{t('gamers')}</h1>
               </Col>
            </Row>
            <Row className='p-five-two'>
               <Col className='pagefive-middle middle-text'>
                  <div className='carousel reveal fade-right'>
                     <Row>
                        <OwlCarousel items={4}
                           className="owl-theme"
                           nav
                           
                           dots={false}
                        >
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/1-Q8-Spanish.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer1_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/q8spanishgaming/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   {/* <a target='_blank' href='/'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a> */}
                                                   <a target='_blank' href='https://www.youtube.com/watch?v=NAKm9adlbSU'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g1_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/q8spanishgaming/'>{t('g1_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/2-Bibi.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>

                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer2_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/bibizumot/?igshid=MzRlODBiNWFlZA'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://m.twitch.tv/bibizumot'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   {/* <a target='_blank' href=''><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a> */}
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g2_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/bibizumot/?igshid=MzRlODBiNWFlZA'>{t('g2_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/3-Lilith.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer3_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/ladylillith/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://www.twitch.tv/ladylillithtv'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   {/* <a target='_blank' href=''><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a> */}
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g3_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/ladylillith/'>{t('g3_handle')}</a></p>

                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/4-Saeed.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>

                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer4_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/saeedxb91/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   {/* <a target='_blank' href='/'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a> */}
                                                   <a target='_blank' href='https://www.youtube.com/@wolfdxb5534'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g4_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/saeedxb91/'>{t('g4_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/5-Sirsan.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>

                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer5_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/sirsanx/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://www.twitch.tv/sirsanx'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   <a target='_blank' href='https://www.youtube.com/@SirSANY/featured'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g5_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/sirsanx/'>{t('g5_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/6-Outlaws.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col></div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer6_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/q8_outlawz/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://m.twitch.tv/q8_outlawz'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   <a target='_blank' href='https://www.youtube.com/@Q8OuTLaWz'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g6_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/q8_outlawz'>{t('g6_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/7-Aamer.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col></div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer7_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/ifn/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://www.twitch.tv/imohdaamer'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   <a target='_blank' href='https://www.youtube.com/@MohdAamer'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g7_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/ifn/'>{t('g7_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/8-Hakum.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer8_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.tiktok.com/@psn_hakoom'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://www.twitch.tv/psn_hakoom'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   {/* <a target='_blank' href=''><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a> */}
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g8_name')}</p>
                                 <p className='place'><a href='https://www.tiktok.com/@psn_hakoom'>{t('g8_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/9-Eighty8 K.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer9_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='https://www.instagram.com/eighty8.k/?igshid=Zjc2ZTc4Nzk%3D'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='https://m.twitch.tv/q8_outlawz'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   <a target='_blank' href='https://www.tiktok.com/@eighty8.k?_t=8ZTyhUsmbha&_r=1'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g9_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/eighty8.k/?igshid=Zjc2ZTc4Nzk%3D'>{t('g9_handle')}</a></p>
                              </div>
                           </div>
                           <div>
                              <div class="scene scene--card">
                                 <div class="flip-card">
                                    <div class="card__face card__face--front">
                                       <Col>
                                          <div className='gamer even-odd'>
                                             <span className='image'><img src={require('../../assets/images/page-component/gamer/10-Waad.png')} name='All 9 Gamers Photo' alt='host' /></span>
                                             <span className='bio'>{t('bio')}</span>
                                          </div>
                                       </Col>
                                    </div>
                                    <div class="card__face card__face--back">
                                       <Col>
                                          <div className='gamer no-image relative'>
                                             <div className='gamer even-odd'>
                                                <span className='image'><img src={require('../../assets/images/page-component/const/gamers-text-bg-bk.webp')} name='All 9 Gamers Photo' alt='host' /></span>
                                             </div>
                                             <div className='no-image-content'>
                                                <span className='detail'>{t('gamer10_detail')}</span>
                                                <div className='social'>
                                                   <a target='_blank' href='/'><img src={require('../../assets/images/page-component/social/IG.webp')} alt='IG' /></a>
                                                   <a target='_blank' href='/'><img src={require('../../assets/images/page-component/social/twitch.webp')} alt='twich' /></a>
                                                   <a target='_blank' href='/'><img src={require('../../assets/images/page-component/social/YT.webp')} alt='YT' /></a>
                                                </div>
                                             </div>
                                          </div>
                                       </Col>
                                    </div>
                                 </div>
                                 <p className='name'>{t('g10_name')}</p>
                                 <p className='place'><a href='https://www.instagram.com/waad.alabdalla/'>{t('g10_handle')}</a></p>
                              </div>
                           </div>
                        </OwlCarousel>
                     </Row>
                  </div>
               </Col>
            </Row>
         </Container>
      </PageFiveWrapper>
   )
};


export default PageFive;
