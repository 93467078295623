import React from 'react';
import { PageFourWrapper } from './PageFour.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

function PageFour() {
   const { t } = useTranslation();
   return (
      <PageFourWrapper>
         <Container fluid className='section page-four' id="hosts">
            <Row className='p-four-one relative'>
               <Col md={1} className='pagefour-middle top-text small-text'>
                  <h5 className='relative'>{t('our')}</h5>
               </Col>
               <Col md={11} className='pagefour-middle top-text big-text'>
                  <h1>{t('hosts')}</h1>
               </Col>
            </Row>
            <Row className='p-four-two'>
               <Col className='pagefour-middle middle-text'>
                  <div className='hosts reveal fade-left'>
                     <Row>
                        <Col sm={6}>
                           <div className='host-image host-one'>
                              <Row>
                                 <Col sm={6}><img src={require('../../assets/images/page-component/host/Baraa.png')} alt='host' />
                                    <p className='name'>{t('h1_name')}</p>
                                    {/* <p className='place'>{t('h1_place')}</p> */}
                                 </Col>
                              </Row>
                           </div>
                        </Col>
                        <Col sm={6}>
                           <div className='host-image host-two'>
                              <Row>
                                 <Col sm={6}> <img src={require('../../assets/images/page-component/host/Tariq.png')} alt='host' />
                                    <p className='name'>{t('h2_name')}</p>
                                    {/* <p className='place'>{t('h2_place')}</p> */}
                                 </Col>
                              </Row>
                           </div>
                        </Col>
                     </Row>
                  </div>
               </Col>
            </Row>
         </Container>
      </PageFourWrapper>
   )
};


export default PageFour;
