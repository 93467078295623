import React from 'react';
import { PageThreeWrapper } from './PageThree.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

function PageThree() {
   const { t } = useTranslation();
   return (
      <PageThreeWrapper>
         <Container fluid className='section page-three' id="watch">
            <Row className='p-three-one'>
               <Col className='pagethree-top top-text reveal fade-bottom'>
                  <h2 className='shine'>
                     {t('fasten')} <br /><b>{t('ready')}</b>
                  </h2>
               </Col>
            </Row>
            <Row className='p-three-two'>
               <Col md={6} className='only-desktop fade-top'>
                  {/* <div className='car-slide-left'>
                     <img src={require('../../assets/images/page-component/const-bg/page-three-only-car-en.png')} alt='highway heroes' />
                  </div> */}
               </Col>
               <Col md={5} className='pagethree-middle middle-text reveal fade-bottom'>
                  <p>{t('gear')}
                     <span className='blue-text'>{t('tournament')}</span>
                     {t('epicgame')}</p>
                  <p>{t('witness')}
                     <span className='blue-text'>{t('gamernumber')}</span>
                     {t('competeinaseries')}
                     <b>{t('GMCYukon')}</b>
                     {t('and')} <b>
                        {t('ChevroletTahoe')}</b>
                     {t('poweredby')}
                  </p>
                  <span className='blue-text reveal fade-bottom'>
                     {t('ruready')}
                  </span>
                  <button onClick={()=> window.open("https://www.youtube.com/playlist?list=PLW4jLjo-ouGci3fWap36lxcWmA2EyFJ0O", "_blank")} className='blue-white reveal fade-bottom' href='https://www.youtube.com/playlist?list=PLW4jLjo-ouGci3fWap36lxcWmA2EyFJ0O'>
                     {t('watchnow')}
                  </button>
                
                
               </Col>
               <Col md={1}></Col>
            </Row>
            <Row className='p-three-three reveal fade-bottom'>
               <h3 className='shine'>{t('partners')}</h3>
               <Col className='pagethree-bottom partners'>
                  <div className='partner-img'>
                     <img src={require('../../assets/images/page-component/const/partners/etilsalat.webp')} alt='etisalat' />
                  </div>
                  <div className='partner-img'>
                     <img src={require('../../assets/images/page-component/const/partners/IGN.webp')} alt='IGN' />
                  </div>
               </Col>
            </Row>
         </Container>
      </PageThreeWrapper>
   )
};


export default PageThree;
