import React from 'react';
import { TeaserWrapper } from './Teaser.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import { useTranslation } from "react-i18next";


function Teaser() {
   const { t } = useTranslation();
   return (
 <TeaserWrapper className='teaser-countdown'>
   <Container>
         <Row className="teaser container d-flex align-items-center flex-column">
            <Col sm={12}>
                  <h2>something exciting <br />is on the way</h2>
                  <div className="countdown">
                  <FlipClockCountdown 
                  to={new Date('2023-09-15').getTime('22:59:59') + 24 * 3600 * 1000 + 5000} 
                  labels={[t('days'), t('hours'), t('minutes'), t('seconds')]} 
                  onTimeUp={() => console.log("Time's up ⏳")}
                  />
                  </div>
               </Col>
         </Row>
         <Row>
            <Col sm><p className="stay-tuned">{t('teaserstaytuned')}</p></Col>
         </Row>
      </Container>
 </TeaserWrapper>
  )
};


export default Teaser;
