import React from 'react';
import { PageTwoWrapper } from './PageTwo.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Counter from '../Counter';
import { useTranslation } from "react-i18next";

function PageTwo() {
   const { t } = useTranslation();
   return (
      <PageTwoWrapper>
         <Container fluid className='section page-two'>
            <Row className='p-two-one'>
               <Col md={8} className='pagetwo-middle middle-text'>
                  <h3 className='reveal fade-left'>{t('indulge')}</h3>
               </Col>
               <Col md={4} className='only-desktop'></Col>
            </Row>
            <Row className='p-two-two reveal fade-right'>
               <Col md={6} className='pagetwo-middle middle-text numbers fade-left'>
                  <Counter number={10} title={t('c_gamers')} />
                  <Counter number={2} title={t('c_cars')} />
                  <Counter number={1} title={t('c_winner')} />
               </Col>
               <Col md={6}>
               </Col>
            </Row>
            <Row className='p-two-two car reveal fade-right-slow'>
               <Col md={6}>
               </Col>
               <Col md={6}>
                  <div className='car-slide-right'>
                     <img src={require('../../assets/images/page-component/const-bg/page-two-car-en.png')} alt='highway heroes' />
                  </div>
               </Col>
            </Row>
         </Container>
      </PageTwoWrapper>
   )
};


export default PageTwo;
