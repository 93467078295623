import React from 'react';
import { FooterWrapper } from './Footer.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";


function Footer  () {
   const { t } = useTranslation();
   return (
      <FooterWrapper className='section footer'>
      <Container>
      {/* <Row className='footer-top-text only-desktop'>
            <Col md={3}>
              <button className='blue-white-long'>{t('visit')} <b>{t('onstararabia')}</b></button>
            </Col>
            <Col md={4}>
            <p>{t('connectedservices')}</p>
               </Col>
         </Row> */}
         <Row className='footer-top-text'>
            <Col>
             <a className='EN' name='LEARN MORE ABOUT ONSTAR CONNECTED SERVICES' href='https://www.onstararabia.com/en/home' target='_blank' rel='noopener noreferrer'>
               <button className='blue-white-long reveal fade-bottom'>{t('connectedservices')}</button>
              </a>
             <a className='AR' name='LEARN MORE ABOUT ONSTAR CONNECTED SERVICES' href='https://www.onstararabia.com/ar/home' target='_blank' rel='noopener noreferrer'>
               <button className='blue-white-long reveal fade-bottom'>{t('connectedservices')}</button>
              </a>
            </Col>
         </Row>
         <Row className='footer-logo reveal'>
            <Col sm><div className='footer-logo'></div></Col>
         </Row>
         <Row className='footer-text'>
            <Col sm><p>{t('copyright')}</p></Col>
         </Row>
      </Container>
   </FooterWrapper>
   )
};

export default Footer;
