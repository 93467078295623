import React from 'react';
import { CountDownWrapper } from './CountDownAr.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TeaserAr from '../TeaserAr/TeaserAr';

function CountDownAr() {
   return (
      <CountDownWrapper>
      <Container fluid className='countdown'>
         <Row>
            <Col>
               <TeaserAr />
               <div className='teaser-footer'>
                  <Row className='footer-logo'>
                     <Col sm><div className='footer-logo'></div></Col>
                  </Row>
                  <Row className='footer-text'>
                     <Col sm><p>جميع الحقوق محفوظة ® 2023 لأونستار</p></Col>
                  </Row>
               </div>
            </Col>
         </Row>
      </Container>
   </CountDownWrapper>
   )
   };


export default CountDownAr;
