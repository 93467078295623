import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import CountDown from './components/CountDown/CountDown'
import GamePage from './components/GamePage/GamePage';
import { LANGUAGES } from "./constants/index";
import { useTranslation } from "react-i18next";
import CountDownAr from './components/CountDownAr/CountDownAr';


function App() {
  const { i18n, t } = useTranslation();
  let lang;
  if (window.location.href.includes('/ar')) {
    lang = 'ar'
    console.log('ar condition lang', lang);
  } else if (window.location.href.includes('/en')) {
    lang = 'en'
    console.log('en condition lang', lang);
  } else {
    lang = t('language');
    console.log('else - lang', lang);
  }
  /*
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };*/

 const onChangeLang = (e) => {
    let lang_code;
    if (window.location.href.includes('/ar')) {
      lang_code = 'ar';
      console.log('lang_code', lang_code)
    } else if (window.location.href.includes('/en')) {
      lang_code = 'en';
      console.log('lang_code', lang_code)
    } else {
      lang_code = e.target.value;
      console.log('lang_code', lang_code)
    }
    i18n.changeLanguage(lang_code);
  };
  
  
  return (
    <Suspense fallback={null}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Router>
        {/* <div className="main-container App" id={t('language')} onLoad={onChangeLang}> */}
        <div className="main-container App" id={lang} onLoad={onChangeLang}>

          {/* <div className='top-links'>
            <div className='lang'>
              <p>{i18n.language}</p>
              <select defaultValue={i18n.language} onChange={onChangeLang}>
                {LANGUAGES.map(({ code, label }) => (
                  <option key={code} value={code}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <Link to={ROUTES.GAMEPAGE + '/' + i18n.language}>
              {t('navtitle1')}-{i18n.language}
            </Link>
            <Link to={ROUTES.COUNTDOWN + '/' + i18n.language}>
              {t('navtitle2')}-{i18n.language}
            </Link>
          </div> */}
          <Routes >
            <Route exact path={ROUTES.COUNTDOWN + '/' + i18n.language} element={<CountDown />} />
            <Route exact path={ROUTES.COUNTDOWN + '/ar'} element={<CountDownAr />} />
            <Route exact path={ROUTES.GAMEPAGE + '/' + i18n.language} element={<GamePage />} />
            <Route path='*' element={<Navigate to={ROUTES.GAMEPAGE + '/' + i18n.language} />} />
            <Route exact path={ROUTES.GAMEPAGE + '/en'} element={<GamePage />} />
            <Route exact path={ROUTES.GAMEPAGE + '/ar'} element={<GamePage />} />
          </Routes>
        </div>
      </Router>
    </Suspense>

  );
}

export default App;


