import React from 'react';
import { PageSixWrapper } from './PageSix.styled';
// import ScheduleOne from '../ScheduleOne/ScheduleOne';
import ScheduleTwo from '../ScheduleTwo/ScheduleTwo';
// import ScheduleThree from '../ScheduleThree/ScheduleThree';


const PageSix = () => (
   <PageSixWrapper id="game">
     {/* <ScheduleOne /> */}
    <ScheduleTwo />
     {/* <ScheduleThree />  */}
   </PageSixWrapper>
);


export default PageSix;
