import React from 'react';
import { ScheduleTwoWrapper } from './ScheduleTwo.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";

function ScheduleTwo() {
   const { t } = useTranslation();
   return (
      <ScheduleTwoWrapper>
         <Container fluid className='section section-2 page-six gamers'>
            <div className='schedule-overlay schedule-overlay-2'>
               <Row className='p-six-one'>
                  <Col className='pagesix-middle top-text small-text'>
                     <h1 className='reveal fade-left'>{t('match')}</h1>
                     <h5 className='reveal fade-right'><i>{t('schedule')}</i></h5>
                  </Col>
               </Row>
               <Row className='p-six-two'>
                  <Col className='pagesix-middle middle-text'>
                  <div className='carousel reveal fade-bottom'>
                  <Row>
                                    <Col md={6}>
                                    <Row className='winner'>
                                          <Col>
                                             <span className='cong white-text'>{t('congratulations')}</span>
                                             <span className='gamer-winner blue-text'>{t('g4_name')}</span>
                                             <span className='team white-text'>{t('teamchevy')}</span>
                                          </Col>
                                       </Row>
                                    </Col>
                                 </Row>
                        {/* <Row>
                           <Col md={6}>
                              <Row className='gamer-vs one-two'>
                                 <Col sm={6}>
                                 <span className='full-name'>{t('g4_handle')}</span>
                                 </Col>
                                 <Col sm={6}>
                                 <span className='full-name'>{t('g6_handle')}</span>
                                 </Col>
                              </Row>
                           </Col>
                        </Row> */}
                        <Row>
                           <Col md={6}>
                              <Row>
                                 <Col>
                                    <img src={require('../../assets/images/page-component/match/chevy-onstar_en.png')} alt='chevy-onstar' />
                                 </Col>
                              </Row>
                              <Row className='gamers-d green'>
                              <Col>
                                    <span className='gamer-name'>{t('g4_handle')}</span>
                                 </Col>
                                  </Row>
                              <Row className='gamers-d green'>
                              <Col>
                                    <span className='gamer-name'>{t('g4_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g2_handle')}</span>
                                 </Col>
                                
                              </Row>
                              <Row className='gamers-d green'>
                              <Col>
                                    <span className='gamer-name'>{t('g2_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g1_handle')}</span>
                                 </Col>
                               
                              </Row>
                              <Row className='gamers-d green'>
                                 <Col>
                                    <span className='gamer-name'>{t('g2_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g5_handle')}</span>
                                 </Col>
                              </Row>
                              <Row className='gamers-d green'>
                                 <Col>
                                    <span className='gamer-name'>{t('g5_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g3_handle')}</span>
                                 </Col>
                              </Row>
                           </Col>
                           <Col md={6}>
                              <Row>
                                 <Col>
                                    <img src={require('../../assets/images/page-component/match/gmc-onstar_en.png')} alt='chevy-onstar' />
                                 </Col>
                              </Row>
                              <Row className='gamers-d green'>
                              <Col>
                                    <span className='gamer-name'>{t('g6_handle')}</span>
                                 </Col>
                                  </Row>
                              <Row className='gamers-d green'>
                              <Col>
                                    <span className='gamer-name'>{t('g6_handle')}</span>
                                 </Col>
                              <Col>
                                    <span className='gamer-name'>{t('g7_handle')}</span>
                                 </Col>
                                  </Row>
                              <Row className='gamers-d green'>
                                <Col>
                                    <span className='gamer-name'>{t('g7_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g8_handle')}</span>
                                 </Col>
                               
                              </Row>
                              <Row className='gamers-d green'>
                                 <Col>
                                    <span className='gamer-name'>{t('g8_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g10_handle')}</span>
                                 </Col>
                              </Row>
                              <Row className='gamers-d green'>
                                 <Col>
                                    <span className='gamer-name'>{t('g8_handle')}</span>
                                 </Col>
                                 <Col>
                                    <span className='gamer-name'>{t('g9_handle')}</span>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                     </div>
                  </Col>
               </Row>
            </div>
         </Container>
      </ScheduleTwoWrapper>
   )
};



export default ScheduleTwo;
