// import React from 'react';
import React from 'react';
import { PageOneWrapper } from './PageOne.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PageOne = () => {
   
   return (
      <PageOneWrapper>
         <Container fluid className='section page-one'>
            <Row className='p-one-two'>
               <Col className='pageone-bottom'></Col>
            </Row>
         </Container>
      </PageOneWrapper>
   )
}


export default PageOne;
