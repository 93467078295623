import React from 'react';
import { GamePageWrapper } from './GamePage.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import PageOne from '../PageOne/PageOne';
import PageTwo from '../PageTwo/PageTwo';
import PageThree from '../PageThree/PageThree';
import PageFour from '../PageFour/PageFour';
import PageFive from '../PageFive/PageFive';
import PageSix from '../PageSix/PageSix';
import VideoComponent from '../VideoComponent/VideoComponent';

const GamePage = () => (
   <GamePageWrapper>
      <Container fluid className='gamepage' id='gamepage'>
         <Row>
            <Col>
               <div className="App">
                  <button
                   name='Navigation Arrow on the Bottom Right'
                     type="button"
                     class="btn-floating btn-lg"
                     id="btn-back-to-top"
                  >
  <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
    </div>
                  </button>
                  <header>
                     <Menu />
                  </header>
                  <body>
                     <PageOne />
                     <VideoComponent />
                     <PageTwo />
                     <PageThree />
                     <PageFour />
                     <PageFive />
                     <PageSix />
                  </body>
                  <footer>
                     <Footer />
                  </footer>
               </div>
            </Col>
         </Row>
      </Container>
   </GamePageWrapper>
);


export default GamePage;
