import React from 'react';
import { CountDownWrapper } from './CountDown.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Teaser from '../Teaser/Teaser';
import { useTranslation } from "react-i18next";

function CountDown() {
   const { t } = useTranslation();
   return (
      <CountDownWrapper>
      <Container fluid className='countdown'>
         <Row>
            <Col>
               <Teaser />
               <div className='teaser-footer'>
                  <Row className='footer-logo'>
                     <Col sm><div className='footer-logo'></div></Col>
                  </Row>
                  <Row className='footer-text'>
                     <Col sm><p>{t('teaserfooter')}</p></Col>
                  </Row>
               </div>
            </Col>
         </Row>
      </Container>
   </CountDownWrapper>
   )
   };


export default CountDown;
