import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = ['en']
const availableLanguages = ['en', 'ar']

i18n
.use(detector)
.use(i18nBackend)
.use(initReactI18next)
.init({
  fallbackLng,
  lng: 'en',
  whitelist: availableLanguages,
  detection: {
    checkWhitelist: true
},
  interpolation: {
    escapeValue: false,
  },
    backend: {
      loadPath: 'http://localhost:3000/i18n/{{lng}}.json',
    },
  resources:{
    en: {
        translation: {
            language: 'en',
            title: 'OnStar Highway Hero',
            navtitle1: 'GAMEPAGE',
            navtitle2: 'COUNTDOWN',
            days:'DAYS',
            hours:'HOURS',
            minutes:'MINUTES',
            seconds:'SECONDS',
            teasertitle: 'something exciting is on the way',
            teaserstaytuned: 'stay tuned',
            teaserfooter:'®2023 all rights reserved to onstar arabia',
            visit:'visit',
            onstararabia: 'onstar arabia',
            connectedservices:'learn more about onstar connected services',
            copyright: '®2023 all rights reserved to onstar arabia',
            teamchevy: 'team chevy',
            teamgmc: 'team gmc',
            gamerfullname1: 'gamer full name',
            gamerfullname2: 'gamer full name',
            gamershortname1: 'short name',
            gamershortname2: 'short name',
            gamername: 'gamer name',
            congratulations: 'congratulations',
            indulge: 'indulge in a truly unique in-vehicle gaming experience',
            fasten:'fasten your seatbelts',
            ready: 'for highway hero',
            gear: 'Get into gear for the ',
            tournament: 'OnStar Highway Hero Tournament,',
            epicgame: ' the most epic mobile gaming tournament on the planet.',
            witness: 'Witness the intense competition as',
            gamernumber: ' 10 gamers',
            competeinaseries: ' compete in a series of 1 x 1 matches, all played from the backseats of connected vehicles – a ',
            GMCYukon: 'GMC Yukon ',
            and: 'and',
            ChevroletTahoe: 'Chevrolet Tahoe ',
            poweredby: 'powered by OnStar’s in-vehicle Wi-Fi, enabled by our carrier partner etisalat by e&. One car will start its journey from Liwa, while the other starts from Ras Al Khaimah, both heading towards the final showdown in Abu Dhabi.',
            ruready: 'Are you ready to be a part of gaming history?',
            watchnow: 'watch now',
            partners: 'partners',
            epicgame_heading: 'the regions 1st mobile gaming tournament powered by in-vehicle wi-fi',
            epicgame_enabled: 'enabled by OnStar & Etisalat',
            our: 'our',
            hosts: 'hosts',
            host1d: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do copy',
            host2d: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do copy',
            the: 'the',
            gamers: 'gamers',
            gamer1_detail: 'A trailblazer into the realm of streaming, saw him lead the charge towards streaming PUBGM games in the region',
            gamer2_detail: 'A renowned Esports content creator, recognized amongst the region’s top gamers due to his skills and humor',
            gamer3_detail: 'A pioneer amongst female gamers, as one of the first Arab female gamers in the UAE and an early adopter among Arab females on Twitch',
            gamer4_detail: 'Gamer who showcases an unmatched passion and expertise in the field of PlayStation games ',
            gamer5_detail: 'Holder of the prestigious title of Best Gaming Content Creator in 2021',
            gamer6_detail: 'Streamer since 2013, in addition to being the first to feature D.vas voice live on Twitch.tv in Kuwait',
            gamer7_detail: 'An Emirati gamer whose infectious humor and engaging streams set him apart from his peers',
            gamer8_detail: 'Renowned holder of five Guinness World Records, as a professional PlayStation gamer',
            gamer9_detail: 'A renowned Egyptian FIFA gamer who landed a spot within the Abtal Esports team, where he is seen as a key player',
            gamer10_detail: 'A partner of the Twitch platform, in addition to his standing as a prominent Emirati gamer and Twitch partner',
            h1_name: 'Baraa',
            h1_handle: 'host1handle',
            h2_name: 'Tariq',
            h2_handle: 'host2handle',
            g1_name: 'Q8 Spanish',
            g1_handle: '@q8spanishgaming',
            g2_name: 'BibiZumot',
            g2_handle: '@bibizumot',
            g3_name: 'Lady Lillith',
            g3_handle: '@ladylillith',
            g4_name: 'Saeed',
            g4_handle: '@wolfdxb91',
            g5_name: 'Sirsan',
            g5_handle: '@sirsanx',
            g6_name: 'Abdullah Alkandari',
            g6_handle: '@q8_outlawz',
            g7_name: 'Mohd Aamer',
            g7_handle: '@ifn',
            g8_name: 'AbdulHakim',
            g8_handle: '@psn_hakoom',
            g9_name: 'Eighty8',
            g9_handle: '@eighty8.k',
            g10_name: 'Waad',
            g10_handle: '@waad.alabdalla',
            m_watch_now: 'watch now',
            m_the_game: 'the game',
            m_hosts: 'hosts',
            m_gamers: 'gamers',
            c_gamers: 'gamers',
            c_cars: 'cars',
            c_winner: 'winner',
            match: 'match',
            schedule: 'schedule',
            bio: 'Click for bio'
        }
    },
    ar: {
        translation: {
            language: 'ar',
            title: 'أبطال القيادة من أونستار',
            navtitle1: 'صفحة اللعبة',
            navtitle2: 'العد التنازلي',
            days:'يوم',
            hours:'ساعة',
            minutes:'دقيقة',
            seconds:'ثانية',
            teaserstaytuned: 'ترقّبونا - لا تفوتوا الحدث - كونوا على الموعد',
            teasertitle: 'كونوا على موعد مع حدث مميز - حدث رائع على الأبواب - ترقّبوا مفاجأتنا الاستثنائية',
            teaserfooter:'جميع الحقوق محفوظة ®  2023 لأونستار',
            visit:'visit',
            onstararabia: 'onstar arabia',
            connectedservices:'اكتشفوا المزيد عن خدمات أونستار المتّصلة',
            copyright: 'جميع الحقوق محفوظة ®  2023 لأونستار',
            teamchevy: 'فريق شيفي',
            teamgmc: 'فريق جي ام سي',
            gamerfullname1: 'gamer full name',
            gamerfullname2: 'gamer full name',
            gamershortname1: 'short name',
            gamershortname2: 'short name',
            gamername: 'gamer name',
            congratulations: 'congratulations',
            indulge: 'اكتشفوا عالماُ لا مثيل له واستمتعوا بتجربة لعب مميزة داخل السيارة ',
            fasten:'اربطوا أحزمة الأمان',
            ready: 'لبطل الطرقات السريعة',
            gear: 'استعدوا للمنافسة مع ',
            tournament: 'بطل الطرقات السريعة - بطل السباقات السريعة من أونستار، ',
            epicgame: 'وعيشوا تجربة مختلفة مع أكثر بطولات ألعاب الهاتف المتحرك داخل السيارة حماساً على الإطلاق. ',
            witness: 'استمتعوا بأجواء حماسية حيث يتنافس  ',
            gamernumber: '10 لاعبين ',
            competeinaseries: ' في سلسلة من مباريات واحد X  واحد، يتم لعبها جميعاً من المقاعد الخلفية',
            GMCYukon: ' لسيارات جي إم سي يوكون ',
            and: 'و',
            ChevroletTahoe: 'شفروليه تاهو ',
            poweredby: 'المتصلة بخدمة الواي فاي داخل السيارة من أونستار بدعم من اتّصالات من &e. ستبدأ أولى السيارتين رحلتها من ليوا، بينما ستبدأ الثانية من رأس الخيمة، وكلاهما يتجه نحو المواجهة النهائية في أبوظبي.',
            ruready: 'مستعدون لتكونوا جزءاً من حدث تاريخي في عالم الألعاب؟',
            watchnow: 'أنتم على موعد مع',
            partners: 'شركاءنا',
            epicgame_heading: 'بطولة ألعاب الهاتف المتحرك الأولى على مستوى المنطقة عبر الاتصال بشبكة واي فاي السيارة',
            epicgame_enabled: 'بدعم من أونستار واتصالات',
            our: '',
            hosts: 'تجمّعنا',
            host1d: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do copy',
            host2d: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do copy',
            the: '',
            gamers: 'أقوى اللاعبين',
            gamer8_detail: 'حاصل على خمسة أرقام قياسية من موسوعة غينيس كلاعب محترف في لعبة PlayStation',
            gamer4_detail: 'يمتلك هذا اللاعب خبرة شغفاً وخبرة بلا مثيل في مجال ألعاب PlayStation',
            gamer5_detail: ' حائز على لقب أفضل صانع محتوى ألعاب لعام ٢٠٢١',
            gamer1_detail: ' رائد في عالم البث المباشر لألعاب الفيديو، وهو من أوائل من قام ببث ألعاب PUBG في المنطقة',
            gamer9_detail: 'لاعب مصري مشهور بلعبة FIFA، حصل على مكان ضمن فريق أبطال Esports، ويعتبر لاعباً رئيسياً في الفريق',
            gamer2_detail: 'صانع محتوى شهير لألعاب الرياضات الإلكترونية، ويعتبره الكثيرون واحداً من بين أفضل اللاعبين في المنطقة بسبب مهاراته وروح الدعابة التي يتمتع بها',
            gamer6_detail: 'مقدم بث مباشر منذ عام ٢٠١٣، كما أنه أول من قام بعرض صوت D.va مباشرة على منصة twitch.tv في الكويت',
            gamer3_detail: 'رائدة بين اللاعبات، باعتبارها واحدة من أوائل اللاعبات العربيات في دولة الإمارات ومن أوائل المشاركات على موقعTwitch بين اللاعبات العربيات',
            gamer7_detail: 'لاعب إماراتي يتميز بروح الدعابة وأسلوب اللعب الجذاب الذي يميزه عن منافسيه',
            gamer10_detail: 'شريكة في منصة Twitch، إضافة إلى مكانتها كلاعبة إماراتية معروفة',
            h1_name: 'براء',
            h1_handle: 'host1handle',
            h2_name: 'طارق',
            h2_handle: 'host2handle',
            g1_name: 'Q8 Spanish',
            g1_handle: '@q8spanishgaming',
            g2_name: 'بيبي زوموت',
            g2_handle: '@bibizumot',
            g3_name: 'ليدي ليليث',
            g3_handle: '@ladylillith',
            g4_name: 'سعيد',
            g4_handle: '@wolfdxb91',
            g5_name: 'سيرسان',
            g5_handle: '@sirsanx',
            g6_name: 'عبدا لله الكندري',
            g6_handle: '@q8_outlawz',
            g7_name: 'محمد عامر',
            g7_handle: '@ifn',
            g8_name: 'عبد الحكيم',
            g8_handle: '@psn_hakoom',
            g9_name: 'Eighty8.k',
            g9_handle: '@eighty8.k',
            g10_name: 'وعد',
            g10_handle: '@waad.alabdalla',
            m_watch_now: 'أنتم على موعد مع',
            m_the_game: ' بطولة حماسيّة',
            m_hosts: 'تجمع',
            m_gamers: 'أقوى اللاعبين',
            c_gamers: 'لاعبين',
            c_cars: 'سيارتان',
            c_winner: 'فائز واحد',
            match: 'جدول',
            schedule: 'المنافسات',
            bio: 'اضغط للبايو'
        }
    },
}
});

export default i18n;