// import React from 'react';
import React, { useRef } from 'react';
import { VideoComponentWrapper } from './VideoComponent.styled';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const VideoComponent = () => {

   const videoRef = useRef(null);
  
   return (
      <VideoComponentWrapper>
       <div class="video-wrapper EN">
       <video ref={videoRef} autoPlay='true' preload='auto' muted='false' loop width="1920px" height="1082px">
            <source src={require('../../assets/images/page-component/video/7Secs_01.mp4')} type="video/mp4" />
         </video>
       </div>
       <div class="video-wrapper AR">
       <video ref={videoRef} autoPlay='true' preload='auto' muted='false' loop width="1920px" height="1082px">
            <source src={require('../../assets/images/page-component/video/7Secs_01.mp4')} type="video/mp4" />
         </video>
       </div>
         <Container fluid className='content section page-video'>
            <Row className='p-video-one'>
               {/* <Col className='pagevideo-middle middle-text'>
                  <h1>{t('epicgame_heading')}</h1>
                  <h5>{t('epicgame_enabled')}</h5>
               </Col> */}
            </Row>
            <Row className='p-video-two'>
               <Col className='pagevideo-bottom'></Col>
            </Row>
         </Container>
         </VideoComponentWrapper>
   )
}


export default VideoComponent;
